import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CalendarDaysIcon, CheckBadgeIcon, ChevronDownIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import authHeader from "../auth-header";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function PageVHL(props) {
  const [vhl, setVhl] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [newKm, setNewKm] = useState("");
  const [newRemarques, setNewRemarques] = useState(vhl.remarques);
  const rev500 = vhl.prochainerevision - 500;
  const [currentUser, setCurrentUser] = useState({});
  const [RDVvhl, setRDVVhl] = useState([]);
  const [HistoRevision, setHistoRevision] = useState([]);
  const [dmdGarage, setDmdGarage] = useState([]);


  const { id } = useParams();

  const [openKM, setOpenKM] = React.useState(false);
  const [openRemarques, setOpenRemarques] = React.useState(false);
  const progress = (vhl.km - Math.floor(vhl.km / 10000) * 10000) / 100;

  const cancelButtonRef = useRef(null)
  const cancelButtonRefRemarques = useRef(null)
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    setEnabled(vhl.pneusneige);
  }, [vhl.pneusneige]);


  const handleClickOpenKM = () => {
    setOpenKM(true);
  };

  const handleClickOpenRemarques = () => {
    setOpenRemarques(true);
  };

  const handleCloseKM = () => {
    setOpenKM(false);
  };

  const handleCloseRemarques = () => {
    setOpenRemarques(false);
  };

  function ValiderKM(e) {
    setOpenKM(false);
    let revisionprov;
    if (currentUser.service === "FOR" || currentUser.service === "FSP" || currentUser.service === "FI" || currentUser.service === "SMS" || currentUser.service === "AMS") {
      revisionprov = Math.ceil(newKm / 10000) * 10000;
    } else {
      revisionprov = Math.ceil(newKm / 10000) * 10000 + 10000;
    }
    const updatedValue = {
      ...vhl,
      km: newKm,
      prochainerevision: revisionprov,
    };
    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, updatedValue, {
        headers: authHeader(),
      })
      .then((res) => {
        setVhl((prevVhl) => ({ ...prevVhl, ...updatedValue }));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  

  function ValiderRemarques(e) {
    setOpenRemarques(false);
    const updatedValue = {
      ...vhl,
      remarques: newRemarques,
    };
    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, updatedValue, {
        headers: authHeader(),
      })
      .then((res) => {
        setVhl((prevVhl) => ({ ...prevVhl, ...updatedValue }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
          setNewRemarques(response.data.remarques);
        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
          setNewRemarques(response.data.remarques);
        })
        .catch((error) => {
          console.log(error);
        });
      }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }, []);

  
  useEffect(() => {
    axios
        .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
        .then(response => {
          setDmdGarage(response.data);
        })
        .catch(error => {
          console.log(error);
        });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
      .then((response) => {
        setRDVVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }, []);

  
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/", { headers: authHeader() })
      .then((response) => {
        setHistoRevision(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSwitchChange = (value) => {
    setEnabled(value);
    setVhl((prevVhl) => {
      const updatedValue = { pneusneige: value };
      const newVhl = { ...prevVhl, ...updatedValue };
      
      axios
        .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, newVhl, {
          headers: authHeader(),
        })
      
      return newVhl;
    });
  };  

  return (

    <div>
  <div className="flex flex-col items-center">
    <img
      className="h-40 w-40 rounded-full object-cover mb-3"
      src={vhl.image}
      alt={vhl.nomvhl}
    />
    <h3 className="text-2xl font-bold text-center">{vhl.nomvhl}</h3>
    <h2 className="text-lg italic text-center mb-6">{vhl.dossardorigine}</h2>
  </div>

  <div className="relative mb-3">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-gray-500">
          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </span>
      </div>
    </div>

    {RDVvhl.some(rdv => rdv.idvhl === vhl._id) && (
  <div className="rounded-md bg-red-50 p-4 mb-2">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">Attention, RDV GARAGE prévu :</h3>
        <div className="mt-2 text-sm text-red-700">
          <ul role="list" className="list-disc space-y-1 pl-5">
            <li>Le {format(new Date(RDVvhl.find(rdv => rdv.idvhl === vhl._id).daterdv), 'dd LLLL yyyy', { locale: fr })}</li>
            <li>Raison : {RDVvhl.find(rdv => rdv.idvhl === vhl._id).typerdv}</li>
            <li>Détails : {RDVvhl.find(rdv => rdv.idvhl === vhl._id).remarque}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)}

        {dmdGarage.some(dmdGarage => dmdGarage.idvhl === vhl._id) && (
          <div className="rounded-md bg-green-50 p-4 mb-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckBadgeIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Demande d'intervention Garage effectuée :</h3>
                <div className="mt-2 text-sm text-green-700">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    <li>Pour : {dmdGarage.find(dmdGarage => dmdGarage.idvhl === vhl._id).details}</li>
                    <li>Par : {dmdGarage.find(dmdGarage => dmdGarage.idvhl === vhl._id).nom}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}


{vhl.km > rev500 && !HistoRevision.some(revision => revision.idvhl === vhl._id && revision.km === vhl.prochainerevision) && (
  <div className="rounded-md bg-red-50 p-4 mb-2">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">Attention à la révision !</h3>
        <div className="mt-2 text-sm text-red-700">
          <ul role="list" className="list-disc space-y-1 pl-5">
            <li>Prévue à {vhl.prochainerevision} km</li>
            <li>Kilométrage actuel : {vhl.km} km</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)}


<div className="mt-4 flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={() => (window.location.href = `/perception/${vhl._id}`)}
    className="mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-indigo-900 opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-white font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">Perception</span>
  </button>
</div>

  <h2 className="text-lg font-semibold text-center mb-3">Situation du véhicule</h2>

  <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-semibold leading-6 text-gray-900">Position</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.position}
    </dd>
  </div>
  <div className="bg-white px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-semibold leading-6 text-gray-900">Emploi</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.emploi}
    </dd>
  </div>
  <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-semibold leading-6 text-gray-900">Utilisateur</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">
      {vhl.utilisateur}
    </dd>
  </div>
  {currentUser.service !== "FOR" && (
    <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Carburant</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
        {vhl.carburant}
      </dd>
    </div>
  )}


<div>
<div className="mt-4 flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={handleClickOpenKM}
    className="mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">Kilométrage : {vhl.km}</span>
  </button>
</div>

<Transition.Root show={openKM} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseKM}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel className="relative transform translate-y-0 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Kilométrage actuel
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      {vhl.km}
                      </p>
                    </div>
                    <div className="mt-2">
                      <textarea
                      rows={1}
                      name="km"
                      id="km"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={newKm}
                      onChange={(e) => setNewKm(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={ValiderKM}
                  >
                    Valider
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={handleCloseKM}
                    ref={cancelButtonRef}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>

  <p>
  <h2 className="text-base font-semibold text-center mb-3">Prochaine révision : {vhl.prochainerevision}</h2>
  </p>
  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
  <div className="bg-indigo-900 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${progress}%` }}>
  {`${Math.floor(progress)}%`}
  </div>
</div>

<div>
<div className="flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={handleClickOpenRemarques}
    className="text-sm -mb-2 mt-4 uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
    <PencilSquareIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Remarques
      </span>
  </button>
</div>

<Transition.Root show={openRemarques} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseRemarques}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel className="relative transform translate-y-0 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Remarques / Détails
                    </Dialog.Title>
                    <div className="mt-2">
                      <textarea
                      rows={5}
                      name="remarques"
                      id="remarques"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                      value={newRemarques}
                      onChange={(e) => setNewRemarques(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                        />
                      </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={ValiderRemarques}
                  >
                    Valider
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={handleCloseRemarques}
                    ref={cancelButtonRefRemarques}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>


    <div className="mt-2 mb-4 flex justify-center items-center">
      <textarea
        rows={4}
        name="comment"
        id="comment"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
        defaultValue={''}
        style={{ width: "300px" }}
        value={newRemarques}
        disabled
      />
    </div>

    {currentUser.service === "FI" && (
  <>
    <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Pneus neige</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
        {vhl.pneusneige ? "Oui" : "Non"}
      </dd>
    </div>

    <Switch.Group as="div" className="mt-2 ml-8 mr-5 flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          Pneus neige
        </Switch.Label>
      </span>
      <Switch
        checked={enabled}
        onChange={handleSwitchChange}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  </>
)}    


  {(currentUser.service === "FOR" || currentUser.service === "SMS") && (
    <>
    {vhl.dossardcompl && (
      <div className="flex items-center mt-5 ">
  <div className="flex-auto">
    <p className="text-base text-center font-semibold leading-6 text-gray-900">Dossard complémentaire</p>
    <p className="mt-1 mb-3 text-center truncate text-sm leading-5 text-gray-500">{vhl.dossardcompl}</p>
  </div>
</div>
      )}

{vhl.dossardbana && (
  <div className="flex items-center mt-5">
    <div className="flex-auto">
      <p className="text-base text-center font-semibold leading-6 text-gray-900">Dossard Bana Totale</p>
      <p className="mt-1 mb-3 text-center truncate text-sm leading-5 text-gray-500">{vhl.dossardbana}</p>
    </div>
  </div>
)}
{vhl.dossardbana && (
  <div className="flex items-center">
    <div className="flex-auto">
      <p className="text-base text-center font-semibold leading-6 text-gray-900">Identité Bana Totale</p>
      <p className="mt-1 mb-5 text-center truncate text-sm leading-5 text-gray-500 whitespace-pre-wrap max-w-[200px] mx-auto">{vhl.identitebana}</p>
    </div>
  </div>
)}



<h2 className="text-lg font-semibold text-center mb-3">Détails du véhicule</h2>

      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Type de VHL
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.typedevhl}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Plaque Magnétique
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.plaquevelcro ? "Oui" : "Non"}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">MAJ Radio</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.majradio}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Chargeur intelligent
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.chargeurintelligent ? "Oui" : "Non"}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">Balisé</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.balise ? "Oui" : "Non"}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Crossclimate Avant
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.pneusavant}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Crossclimate Arrière
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.pneusarriere}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Taille pneus
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.taillepneus}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">AD-Blue</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.adblue ? "Oui" : "Non"}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Taille du VHL
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.taillevhl}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">Poids VHL</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.poidsvhl}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">Pneus neige</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {vhl.pneusneige ? "Oui" : "Non"}
        </dd>
      </div>

      <Switch.Group as="div" className="mt-2 ml-8 mr-5 flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          Pneus neige
        </Switch.Label>
      </span>
      <Switch
        checked={enabled}
        onChange={handleSwitchChange}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
    </>
  )}

    <h2 className="mt-6 text-lg font-semibold text-center mb-3">Cartes essences</h2>

    <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        Carte Total
      </dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
        {vhl.carteEssSchell}
      </dd>
    </div>
    <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        Date Exp. Total
      </dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
        {new Date(vhl.dateCarteEssSchell).toLocaleDateString()}
      </dd>
    </div>
    <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">Carte Shell</dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
        {vhl.carteEssTotal}
      </dd>
    </div>
    <div className="bg-white px-8 py-2 flex flex-row items-center">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        Date Exp. Shell
      </dt>
      <dd className="ml-auto text-sm leading-6 text-gray-700">
      {new Date(vhl.dateCarteEssTotal).toLocaleDateString()}
      </dd>
    </div>

<div className="mt-4 flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={() => (window.location.href = `/historiqueperception/${vhl._id}`)}
    className="mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
    <CalendarDaysIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Historique des perceptions</span>
  </button>
</div>

<div className="flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={() => (window.location.href = `/historiquerevision/${vhl._id}`)}
    className="mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">Historique des révisions</span>
  </button>
</div>
</div>

  );
}

export default PageVHL;
